<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button class="ml-2"/>
    <div class="ml-2 d-flex justify-content-between">
      <h1>{{ 'Edit Program User' }}</h1>
    </div>
    <program-user-form ref="form" :program-user="programUser" @update="updateProgramUser" form-type="update" :loader="loader"/>
  </div>
</template>

<script>
import ProgramUserForm from "@/views/Components/SuperAdmin/ProgramUser/Form";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditProgramUser",
  components: {
    ProgramUserForm,
    BackButton
  },
  data() {
    return {
      loader: false,
      programUser: null,
    }
  },
  mixins: [generateErrorMessageMixin],
  mounted() {
    this.getProgramUser()
  },
  methods: {
    /**
     * Get Requested Program User
     */
    getProgramUser() {
      let vm = this
      this.loader = true
      vm.$store.dispatch('ProgramUserModule/_getRequestedProgramUser', {id: this.$route.params.id})
        .then(response => {
          this.programUser = response.data.data
        })
        .catch(error => {
          if (error?.response?.status == '404') {
            this.$router.push({name: 'admin.program_user'})
          }
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Add Program User
     * @param formData
     */
    updateProgramUser(formData) {
      let vm = this
      this.loader = true
      vm.$store.dispatch('ProgramUserModule/_updateProgramUser', {formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Program User Updated Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Program User',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  },
}
</script>

<style scoped>

</style>
